import React from 'react';
import { Card } from '@fairwindsops/ui-components';

import Steps from '../steps/Steps.react';

import { steps } from '../../auth.config.react';
import { RegisterProps } from '../../auth.types.react';

import './style.scss';

const Register = ({ route, router, store, AuthComponent }: RegisterProps) => {
  const { activeStep } = store()?.getters;
  // activeStep updated in beforeEnter in auth route file; should we move to when we click on next?

  return (
    <div className="register-pages">
      <div className="register-pages__main">
        <Steps allSteps={steps} activeStep={activeStep} />
        <Card className="form-container">
          {/* if route includes new-organization, what is shown? */}
          <Card.Body padded className="form-container__body">
            {!route?.path?.includes('confirmation') && (
              <>
                <div className="form-container__header">
                  <h1 className="form-container__title">Sign Up for Insights</h1>
                  <p className="form-container__sub-title">
                    Fairwinds Insights is free for up to two Kubernetes clusters.
                  </p>
                  <a href="http://fairwinds.com/pricing" target="_blank">
                    View pricing for scale
                  </a>
                  {route?.path?.indexOf('datadog') !== -1 && (
                    <p>
                      To complete your purchase of Fairwinds Insights from the Datadog Marketplace,
                      please fill out the form below.
                    </p>
                  )}
                </div>
                {activeStep === 1 && <AuthComponent route={route} router={router} />}
                {activeStep === 2 && <AuthComponent router={router} store={store} />}
              </>
            )}
            {route?.path?.includes('confirmation') && (
              <div className="extra-top-padding">
                {activeStep === 3 && !route?.path?.includes('success') ? (
                  <AuthComponent />
                ) : (
                  <AuthComponent router={router} store={store} />
                )}
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Register;
