import React, { useState, useMemo } from 'react';
import { Form, Button } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

import { Card } from '@fairwindsops/ui-components';
import { LOGIN, RECOVER_EMAIL } from '~reactComponents/NavigationReact/Navigation.config.react';

import { IRouter } from '~utils/global.types.react';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { strings } from '~utils/strings';

import '../style.scss';

const RecoverStart = ({ router }: { router: () => IRouter }) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const isEmailValid = useMemo(() => {
    const isValid = email.match(/^\S+@\S+\.[a-z]{1,}$/);

    if (email.length && !isValid) {
      setError('Email is invalid');
      return false;
    } else if (!email.length) {
      setError('');
      return false;
    }
    setError('');
    return true;
  }, [email]);

  const sendRecoverPassword = async () => {
    let result;

    try {
      result = await sendRequest('POST', '/v0/auth/recover', { data: { email } }, null);

      if (result) {
        if (result.Failure || result.status === strings.noTranslate.failure) {
          setError(result.errors.email[0]);
        } else if (result.Success !== '') {
          router().push({ name: RECOVER_EMAIL });
        }
      }
    } catch (e) {
      toast.error('Error while starting password recovery process');
      logger.logError('error_recovering_password', e);
    }
  };

  return (
    <>
      <Card className="recover">
        <Card.Header className="recover__header">
          <h1 className="recover__title">{strings.auth.resetPassword}</h1>
          <p className="recover__sub-title">
            We'll send you an email with instructions to reset it.
          </p>
        </Card.Header>
        <Card.Body padded>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              sendRecoverPassword();
            }}
            noValidate
          >
            <Form.Group>
              <Form.Label htmlFor="recover-password-email">{strings.Email}</Form.Label>
              <Form.Control
                id="recover-password-email"
                data-cy="email-text-input"
                type="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              data-cy="submit-reset-password-button"
              disabled={!isEmailValid}
            >
              {strings.resetPassword}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <a href={router().resolve({ name: LOGIN }).href} data-cy="have-account-button">
        Already have an account?
      </a>
      <Toaster />
    </>
  );
};

export default RecoverStart;
