import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';

import AudioIcon from '~reactComponents/Icons/Audio.icon.react';

import { PROFILE_INFO } from '~reactComponents/NavigationReact/Navigation.config.react';
import { ONRAMP_FREE_TIER_KEYS } from '~utils/global.helpers.react';

import { userInfoValidationSchema } from '../../auth.config.react';

import { getCaptchaImage, playCaptchaAudio } from '~views/auth/auth.helpers.react';

import { RoutingProps } from '../../auth.types.react';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

import './style.scss';

const UserInfoComponent = ({ route, router }: RoutingProps) => {
  const [captchaId, setCaptchaId] = useState<string>('');
  const [captchaError, setCaptchaError] = useState<string>('');

  const {
    watch,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(userInfoValidationSchema),
    mode: strings.noTranslate.onSubmit,
  });

  useEffect(() => {
    if (!captchaId) {
      getCaptchaId();
    }
  });

  const valid = useMemo(() => {
    return Boolean(
      watch('email') &&
        watch('password') &&
        watch(strings.noTranslate.confirmPassword) &&
        Boolean(watch(strings.noTranslate.captcha).length === 6),
    );
  }, [
    watch('email'),
    watch('password'),
    watch(strings.noTranslate.confirmPassword),
    watch(strings.noTranslate.captcha),
  ]);

  const getCaptchaId = async () => {
    let result;
    const noCaptcha = 'No Captcha';

    try {
      result = await sendRequest('GET', '/v0/captcha/generate?from=register', {}, null);
      setCaptchaId(result?.captchaId === noCaptcha ? null : result.captchaId);
    } catch (e) {
      logger.logError('error_getting_captcha_id_register_form', e);
      toast.error(strings.contactUs.errorGettingCaptcha);
    }
  };

  const buildRequestPayload = (input: Record<string, string>) => {
    const callbackUrl = localStorage.getItem(ONRAMP_FREE_TIER_KEYS.callbackUrl);
    return captchaId && captchaId !== 'No Captcha'
      ? {
          email: input.email,
          password: input.password,
          confirm_password: input.confirmPassword,
          tos: 'true',
          captchaId,
          captchaValue: input.captcha,
          from: strings.noTranslate.register,
          callbackUrl,
        }
      : {
          email: input.email,
          password: input.password,
          confirm_password: input.confirmPassword,
          tos: 'true',
          callbackUrl,
        };
  };

  const handleFailedSubmit = async (result: Record<string, any>) => {
    await getCaptchaId();

    if (result?.errors?.captcha) {
      setCaptchaError(result.errors.captcha[0]);
      toast.error(result.errors.captcha[0]);
    } else {
      setError('root.serverError', { type: 'custom', message: result.errors[''][0] });
    }
  };

  const handleOnSubmit = handleSubmit(async (input) => {
    const toSave = buildRequestPayload(input);
    const source = localStorage.getItem(ONRAMP_FREE_TIER_KEYS.source);
    let url = '/v0/auth/register';
    let result;

    if (route?.path?.indexOf('datadog') !== -1) {
      url += '?source=datadog';
    } else if (source) {
      url += `?source=${source}`;
    }

    try {
      result = await sendRequest('POST', url, { data: toSave, showSuccessAlert: false }, null);
      if (result && result.errors) {
        handleFailedSubmit(result);
      } else if (result && result.Success !== '') {
        router().push({ name: PROFILE_INFO });
      }
    } catch (e) {
      if (result && result.errors) {
        handleFailedSubmit(result);
      } else {
        toast.error(strings.userProfile.errorSigningUp);
        await getCaptchaId();
      }
    }
  });

  return (
    <>
      {errors?.root?.serverError && (
        <Alert variant="danger" className="sign-up-form-error">
          {errors?.root?.serverError?.message}
        </Alert>
      )}
      <Form onSubmit={handleOnSubmit} className="sign-up-form">
        <Form.Group>
          <Form.Label htmlFor="sign-up-email">{strings.Email}</Form.Label>
          <Form.Control
            required
            id="sign-up-email"
            type="text"
            data-cy="email-text-input"
            autoComplete="email"
            title={strings.Email}
            isInvalid={!!errors?.email}
            {...register('email')}
          />
          <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="sign-up-password">{strings.navigation.Password}</Form.Label>
          <Form.Control
            required
            id="sign-up-password"
            type="password"
            data-cy="password-text-input"
            autoComplete="new-password"
            title={strings.navigation.Password}
            isInvalid={!!errors?.password}
            {...register('password')}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.password?.type === 'string.pattern.base'
              ? 'Password requires 1 number and 1 symbol and be at least 8 characters'
              : errors?.password?.message || ''}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="sign-up-confirm-password">
            {strings.userProfile.confirmPassword}
          </Form.Label>
          <Form.Control
            required
            id="sign-up-confirm-password"
            type="password"
            data-cy="confirm-password-text-input"
            autoComplete="new-password"
            title={strings.userProfile.confirmPassword}
            isInvalid={!!errors?.confirmPassword}
            {...register(strings.noTranslate.confirmPassword)}
          />
          <Form.Control.Feedback type="invalid">
            {errors?.confirmPassword?.type === 'any.only'
              ? 'Does not match password'
              : errors?.confirmPassword?.message || ''}
          </Form.Control.Feedback>
        </Form.Group>
        {captchaId && (
          <Form.Group>
            <Form.Label htmlFor="sign-up-captcha">{strings.contactUs.Captcha}</Form.Label>
            <div className="sign-up-form__captcha-image">
              <img src={getCaptchaImage(captchaId)} alt={captchaId || ''} />
              <div
                className="sign-up-form__audio-container"
                onClick={() => playCaptchaAudio(captchaId)}
              >
                <AudioIcon />
              </div>
            </div>
            <Form.Control
              required
              id="sign-up-captcha"
              type="text"
              data-cy="captcha-text-input"
              autoComplete="one-time-code"
              title={strings.contactUs.Captcha}
              isInvalid={!!captchaError}
              {...register(strings.noTranslate.captcha)}
            />
            <Form.Control.Feedback type="invalid">{captchaError || ''}</Form.Control.Feedback>
          </Form.Group>
        )}
        <p>
          By clicking 'Next' I acknowledge I have read and agree to the terms of the{' '}
          <a
            href="https://www.fairwinds.com/insights-free-tier-terms-and-conditions"
            target="_blank"
          >
            Free Tier Terms and Conditions
          </a>{' '}
        </p>
        <Button variant="primary" type="submit" data-cy="submit-user-button" disabled={!valid}>
          {strings.general.Next}
        </Button>
      </Form>
      <Toaster />
    </>
  );
};

export default UserInfoComponent;
