import React, { useMemo } from 'react';
import { Form, Button } from 'react-bootstrap';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';

import { Card } from '@fairwindsops/ui-components';

import { ORGS } from '~reactComponents/NavigationReact/Navigation.config.react';

import { recoverEndValidationSchema } from '~views/auth/auth.config.react';

import { RoutingProps } from '~views/auth/auth.types.react';

import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { strings } from '~utils/strings';

import '../style.scss';

const RecoverEnd = ({ route, router }: RoutingProps) => {
  const recoverToken = route?.query?.token;
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(recoverEndValidationSchema),
    mode: strings.noTranslate.onSubmit,
  });

  const sendRecoverEnd = handleSubmit(async (input) => {
    const data = {
      password: input.password,
      confirm_password: input.confirmPassword,
      token: recoverToken,
    };
    let result;

    try {
      result = await sendRequest('POST', '/v0/auth/recover/end', { data }, null);

      if (result) {
        if (result.status === strings.noTranslate.failure || result.errors) {
          if (result.errors[''][0] === 'recovery token is invalid') {
            toast.error('Check your password reset email and try again');
          } else {
            toast.error(result.errors[''][0]);
          }
        } else {
          toast.success(strings.Success);
          router().push({ name: ORGS });
        }
      }
    } catch (e) {
      logger.logError('error_resetting_password', e);
      toast.error('Error while changing password');
    }
  });

  const isValid = useMemo(() => {
    return Boolean(watch('password') && watch(strings.noTranslate.confirmPassword) && recoverToken);
  }, [watch('password'), watch(strings.noTranslate.confirmPassword), recoverToken]);

  return (
    <Card className="recover">
      <Card.Header className="recover__header">
        <h1 className="recover__title">Password Reset</h1>
        <p className="recover__sub-title">Use the form below to create a new password</p>
      </Card.Header>
      <Card.Body padded>
        <Form onSubmit={sendRecoverEnd} noValidate>
          <Form.Group>
            <Form.Label htmlFor="recover-password">{strings.navigation.Password}</Form.Label>
            <Form.Control
              required
              id="recover-password"
              type="password"
              autoComplete="new-password"
              title={strings.navigation.Password}
              isInvalid={!!errors?.password}
              {...register('password')}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.password?.type === 'string.pattern.base'
                ? strings.auth.passwordValidationError
                : errors?.password?.message || ''}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="recover-confirm-password">
              {strings.userProfile.confirmPassword}
            </Form.Label>
            <Form.Control
              required
              id="recover-confirm-password"
              type="password"
              autoComplete="new-password"
              title={strings.userProfile.confirmPassword}
              isInvalid={!!errors?.confirmPassword}
              {...register(strings.noTranslate.confirmPassword)}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.confirmPassword?.type === 'any.only'
                ? strings.userProfile.confirmPasswordError
                : errors?.confirmPassword?.message || ''}
            </Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" variant="primary" disabled={!isValid}>
            {strings.resetPassword}
          </Button>
        </Form>
      </Card.Body>
      <Toaster />
    </Card>
  );
};

export default RecoverEnd;
