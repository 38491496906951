import * as React from 'react';
import { COLORS } from '~utils/styling';

type AudioIconProps = {
  width?: number;
  height?: number;
  fill?: string;
};

const AudioIcon = ({
  width = 20,
  height = 20,
  fill = 'none',
  ...props
}: AudioIconProps): JSX.Element => {
  return (
    <svg width={width} height={height} fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m12.991 19.053.008.005c.138.083.31.142.49.142a.73.73 0 0 0 .424-.12.857.857 0 0 0 .46-.764V1.676a.857.857 0 0 0-.475-.772c-.281-.14-.633-.148-.907.035l-7.7 5.096H2.684a.872.872 0 0 0-.884.883v6.178c0 .452.386.861.884.861H5.29l7.7 5.096Zm-9.423-6.864V7.78h1.104v4.41H3.568Zm2.85-4.821 6.164-4.067v13.367l-6.165-4.067V7.368ZM15.429 15.045l.007.006a.923.923 0 0 0 .584.229.846.846 0 0 0 .65-.294 7.28 7.28 0 0 0 1.966-4.873v-.002c0-1.807-.681-3.458-1.98-4.639a.882.882 0 0 0-1.24.058.882.882 0 0 0 .058 1.24l.001.001c.912.825 1.415 2.022 1.394 3.336v.004c0 1.336-.526 2.634-1.498 3.694a.882.882 0 0 0 .058 1.24Z"
        fill={COLORS.CORE.PRIMARY}
        stroke={COLORS.CORE.PRIMARY}
        strokeWidth=".4"
      />
    </svg>
  );
};

export default AudioIcon;
