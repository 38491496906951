import React from 'react';
import UserInfoComponent from './UserInfoComponent.react';

import Register from '../registerPage/Register.react';

import { BaseProps } from '../../auth.types.react';

const UserInfo = ({ route, router, store }: BaseProps) => {
  return <Register route={route} router={router} store={store} AuthComponent={UserInfoComponent} />;
};

export default UserInfo;
