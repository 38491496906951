import Login from './Login.react';

export default {
  components: {
    login: Login,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
