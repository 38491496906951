import * as React from 'react';
import { COLORS } from '~utils/styling';

interface InfoIconProps {
  className?: string;
  width?: string;
  height?: string;
}

function InfoIcon(props: InfoIconProps) {
  return (
    <svg
      viewBox="0 0 14 14"
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="7" cy="7" r="7" fill={COLORS.CORE.PRIMARY} />
      <path d="M6.6 11.6V5.55h.97v6.03H6.6ZM6.5 3h1.19v1.1H6.5V3Z" fill={COLORS.CORE.WHITE} />
    </svg>
  );
}

export default InfoIcon;
