import React from 'react';

import './style.scss';

type Step = {
  heading: string;
  stepNumber: number;
  description: string;
};

type StepsProps = {
  allSteps: Step[];
  activeStep: number;
};

const Steps = ({ allSteps, activeStep = 1 }: StepsProps) => {
  const Step = ({ step }: { step: Step }) => {
    return (
      <div
        className={`step ${step.stepNumber === activeStep ? 'active' : ''}`}
        key={step.stepNumber}
        data-number={step.stepNumber}
      >
        <h4>{step.heading}</h4>
        <p>{step.description}</p>
      </div>
    );
  };
  return (
    <div className="step-container">
      <div className="step-container__vertical-steps">
        {allSteps.map((step) => (
          <Step step={step} />
        ))}
      </div>
    </div>
  );
};

export default Steps;
