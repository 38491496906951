import RecoverStart from './RecoverStart.react';

export default {
  components: {
    RecoverStart,
  },
  methods: {
    router() {
      return this.$router;
    },
  },
};
