export const getCaptchaImage = (captchaId: string) => {
  return `/v0/captcha?id=${captchaId}&ext=png&lang=en&download=false`;
};

export const playCaptchaAudio = (captchaId: string) => {
  if (captchaId) {
    const audio = new Audio(`/v0/captcha?id=${captchaId}&ext=wav&lang=en&download=false`);
    audio.play();
  }
};
