import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import toast, { Toaster } from 'react-hot-toast';

import {
  profileInfoValidationSchema,
  reasonOptions,
  referralOptions,
} from '../../auth.config.react';
import { ProfileInfoType, CountryType, PartialBaseProps } from '../../auth.types.react';

import { CHECK_CONFIRMATION } from '~reactComponents/NavigationReact/Navigation.config.react';
import { PersonalEmailProviders } from '~utils/constants';
import { strings } from '~utils/strings';
import { handlePageChange } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { BasicText } from '~utils/texts.react';

import './ProfileInfo.react.scss';

const ProfileInfoComponent = ({ router, store }: PartialBaseProps) => {
  const [countries, setCountries] = useState<CountryType[]>([]);
  const user = store().getters.user;
  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ProfileInfoType>({
    resolver: joiResolver(profileInfoValidationSchema),
    mode: strings.noTranslate.onSubmit,
  });

  useEffect(() => {
    const getCountries = async () => {
      let response;
      try {
        response = await sendRequest('GET', '/v0/countries', {}, null);
      } catch (e) {
        logger.logError('error_retrieving_countries', e);
      }

      setCountries(response);
    };

    getCountries();
  }, []);

  const countriesOptions = useMemo(
    () => countries.map((country: CountryType) => ({ value: country.alpha2, label: country.name })),
    [countries],
  );

  const isPersonalEmail = useMemo(
    () => PersonalEmailProviders.includes((user?.Email || '').split('@')[1]),
    [user],
  );

  const otherSelected = useMemo(() => watch('referral')?.value === 'Other', [watch('referral')]);

  const handleOnSubmit = handleSubmit(async (input) => {
    let result;
    const surveyData = {
      firstName: input.firstName,
      lastName: input.lastName,
      company: input.company || '',
      referral: input.referral.value,
      reasonCode: input.reason.value,
      countryAlpha2: input.country.value,
    };

    if (input.referralOther) {
      surveyData.otherReferral = input.referralOther;
    }

    try {
      result = await sendRequest('POST', '/v0/survey', { data: surveyData }, null);
      if (result?.Success) {
        handlePageChange(router, CHECK_CONFIRMATION);
      }
    } catch (e) {
      toast.error(strings.register.signUpError);
      logger.logError('error_sending_survey_profile', e);
    }
  });

  return (
    <div className="profile-info">
      <Form onSubmit={handleOnSubmit} noValidate>
        {isPersonalEmail && (
          <Form.Group>
            <Form.Label>{strings.register.company}</Form.Label>
            <Form.Control
              placeholder={strings.register.companyName}
              type="text"
              {...register('company', { maxLength: 64 })}
              className={errors?.company && 'is-invalid'}
            />
            <Form.Control.Feedback type="invalid">{errors?.company?.message}</Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>{strings.register.firstName}</Form.Label>
          <Form.Control
            type="text"
            placeholder={strings.register.name}
            {...register('firstName', { maxLength: 64 })}
            className={errors?.firstName && 'is-invalid'}
            data-cy="first-name-text-input"
          />
          <Form.Control.Feedback type="invalid">{errors?.firstName?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>{strings.register.lastName}</Form.Label>
          <Form.Control
            type="text"
            placeholder={strings.register.name}
            {...register('lastName', { maxLength: 64 })}
            className={errors?.lastName && 'is-invalid'}
            data-cy="last-name-text-input"
          />
          <Form.Control.Feedback type="invalid">{errors?.lastName?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Label>{strings.register.country}</Form.Label>
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={countriesOptions}
              menuPlacement={strings.noTranslate.auto}
              aria-label="country dropdown"
              className="country-dropdown"
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="country"
          render={() => (
            <p className={BasicText({ color: 'danger' })}>{strings.register.countryRequired}</p>
          )}
        />
        <Form.Label className="profile-info__dropdown-label">
          {strings.register.mainReason}
        </Form.Label>
        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={reasonOptions.map((reason) => ({
                value: reason.value,
                label: reason.label,
              }))}
              menuPlacement={strings.noTranslate.auto}
              aria-label="why are you interested dropdown"
              className="sign-up-reason-dropdown"
              classNamePrefix="sign-up-reason-dropdown"
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="reason"
          render={() => (
            <p className="profile-info__error-message">{strings.register.isRequired}</p>
          )}
        />
        <Form.Label className="profile-info__dropdown-label" data-cy="referral-dropdown">
          {strings.register.hearAboutUs}
        </Form.Label>
        <Controller
          name="referral"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              options={referralOptions.map((option) => ({ value: option, label: option }))}
              menuPlacement={strings.noTranslate.auto}
              aria-label="how were you referred dropdown"
              className="referral-dropdown"
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="referral"
          render={() => (
            <p className="profile-info__error-message">{strings.register.isRequired}</p>
          )}
        />
        {otherSelected && (
          <Form.Group className="profile-info__other-selected">
            <Form.Control
              type="text"
              {...register('referralOther', { maxLength: 280, minLength: 2, required: true })}
              placeholder={strings.register.hearAboutUs}
            />
            <ErrorMessage
              errors={errors}
              name="referralOther"
              render={() => <p className="error-message">{strings.register.isRequired}</p>}
            />
          </Form.Group>
        )}
        <div className="profile-info__submit-btn">
          <Button
            variant="primary"
            type="submit"
            className="profile-info__next-btn"
            data-cy="submit-profile-button"
            disabled={!isValid}
          >
            {strings.general.Next}
          </Button>
        </div>
      </Form>
      <Toaster />
    </div>
  );
};

export default ProfileInfoComponent;
