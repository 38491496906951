import Joi from 'joi';
import { strings } from '~utils/strings';

export const loginValidationSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().required(),
  captcha: Joi.string().allow(null, '').required(),
});

export const profileInfoValidationSchema = Joi.object({
  company: Joi.string().optional(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  referral: Joi.object({
    label: Joi.string(),
    value: Joi.string(),
  }).required(),
  referralOther: Joi.string(),
  reason: Joi.object({
    label: Joi.string(),
    value: Joi.string(),
  }).required(),
  country: Joi.object({
    label: Joi.string(),
    value: Joi.string(),
  }).required(),
});

export const userInfoValidationSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string()
    .pattern(new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9]{1,})(?=.*[-+_!@#$%^&*.,?]{1,}).{8,}$/))
    .required(),
  confirmPassword: Joi.ref('password'),
  captcha: Joi.string().allow(null, '').required(),
});

export const recoverEndValidationSchema = Joi.object({
  password: Joi.string()
    .pattern(new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])(.{8,})$/))
    .required(),
  confirmPassword: Joi.ref('password'),
});

export const referralOptions = [
  strings.cncf,
  strings.github,
  strings.goldilocks,
  strings.register.news,
  strings.nova,
  strings.pluto,
  strings.polaris,
  strings.register.webinar,
  strings.register.wordOfMouth,
  strings.register.Other,
];

export const reasonOptions = [
  { label: strings.register.automate, value: 'automate_compliance_and_security' },
  { label: strings.register.consistency, value: 'enforce_consistency' },
  { label: strings.register.configIssues, value: 'help_developers_fix_issues' },
  { label: strings.register.optimize, value: 'optimize_costs' },
];

export const steps = [
  {
    heading: 'User Info',
    stepNumber: 1,
    description: 'This is where you tell a little bit about you',
  },
  {
    heading: 'Profile Info',
    stepNumber: 2,
    description: 'Tell us where you work and your basic user info',
  },
  {
    heading: 'Email Confirmation',
    stepNumber: 3,
    description: 'Verify the email you provided as the last step',
  },
];
