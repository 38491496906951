import React from 'react';

import ProfileInfoComponent from './ProfileInfoComponent.react';
import Register from '../registerPage/Register.react';

import { BaseProps } from '../../auth.types.react';

const ProfileInfo = ({ route, router, store }: BaseProps) => {
  return (
    <Register route={route} router={router} store={store} AuthComponent={ProfileInfoComponent} />
  );
};

export default ProfileInfo;
