import React, { useState, useEffect } from 'react';
import { Form, Button, OverlayTrigger, Popover } from 'react-bootstrap';

import { Card } from '@fairwindsops/ui-components';

import InfoIcon from '~reactComponents/Icons/InfoIcon.react';

import {
  CHECK_CONFIRMATION,
  LOGIN,
  ORGS,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { LoginProps } from '../auth.types.react';

import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

import './style.scss';

const SSO = ({ router, route, store }: LoginProps) => {
  const [error, setError] = useState<string>('');
  const [orgID, setOrgID] = useState<string>(() => {
    return localStorage.getItem(strings.noTranslate.ssoOrg) || '';
  });

  useEffect(() => {
    const { user } = store().getters;
    if (user && user.FirstName && user.LastName && user.Confirmed) {
      if (!route?.query?.forceLogin) {
        router().push({ name: ORGS });
      }
    } else if (user && user.FirstName && user.LastName) {
      router().push({ name: CHECK_CONFIRMATION });
    }
  }, []);

  const loginWithSSO = async () => {
    const samlLink = `/v0/organizations/${orgID}/auth/saml`;
    setError('');

    try {
      const response = await sendRequest('GET', samlLink, {}, null);

      if (response) {
        localStorage.setItem(strings.noTranslate.ssoOrg, orgID);
        window.open(samlLink, strings.noTranslate.self);
      }
    } catch (e) {
      logger.logError('error_getting_saml_link', e);
      if (![404].includes(e.status)) {
        localStorage.setItem(strings.noTranslate.ssoOrg, orgID);
        window.open(samlLink, strings.noTranslate.self);
      } else {
        setError(strings.auth.noSAMLIntegration);
      }
    }
  };

  return (
    <Card className="sso-login">
      <Card.Header className="sso-login__header">
        <h1 className="sso-login__title">{strings.auth.samlTitle}</h1>
        <div>
          <span className="sso-login__sub-title">{strings.auth.enterOrgName}</span>
          <OverlayTrigger
            placement="right"
            overlay={
              <Popover>
                <Popover.Content>{strings.auth.ssoTooltip}</Popover.Content>
              </Popover>
            }
          >
            <InfoIcon />
          </OverlayTrigger>
        </div>
      </Card.Header>
      <Card.Body padded>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            loginWithSSO();
          }}
          noValidate
        >
          <Form.Group>
            <Form.Label htmlFor="org-name">{strings.auth.orgName}</Form.Label>
            <Form.Control
              id="org-name"
              type="text"
              title={strings.auth.orgName}
              onChange={(e) => setOrgID(e.target.value)}
              isInvalid={!!error}
              defaultValue={orgID}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" variant="primary" disabled={!orgID}>
            {strings.general.Submit}
          </Button>
        </Form>
        <div className="sso-login__sign-in-options">
          <a href={router().resolve({ name: LOGIN }).href} data-cy="other-sign-in-button">
            {strings.auth.otherSignInOptions}
          </a>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SSO;
